import { db } from "@kanpla/system";
import { DrawerOrModal } from "@kanpla/ui";
import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";
import { useContainer } from "unstated-next";
import useCancelRefill from "../../../lib/useCancelRefill";
import { AppContext } from "../../contextProvider";
import { Form, InputNumber, message } from "antd";
import { useForm } from "antd/lib/form/Form";

interface Props {
  open: boolean;
  setOpen: (nextState: boolean) => void;
}

const RefillSettings = (props: Props) => {
  const { open, setOpen } = props;
  const { t } = useTranslation(["translation", "modals"]);
  const { user, userId } = useContainer(AppContext);
  const [loading, setLoading] = useState(false);
  const { handleCancel } = useCancelRefill();
  const [form] = useForm();

  const hasAutomaticBestilling =
    user && user?.refillBelow && user?.refillAmount;

  const handleSubmit = async ({ refillBelow, refillAmount }) => {
    try {
      if (!refillBelow || !refillAmount)
        throw new Error(t("translation:message.error.choose-amount"));

      setLoading(true);

      const data = {
        refillBelow: parseInt(refillBelow + ""),
        refillAmount: parseInt(refillAmount + ""),
      };

      await db.collection("users").doc(userId).update(data);

      setOpen(false);
      setLoading(false);
      message.success(t("modals:message.success.automatic-refueling-is-set"));
    } catch (e) {
      setLoading(false);
      message.error(e.message);
    }
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("modals:modal.titles.auto-refill")}
      containerClassName="flex"
      actions={[
        {
          onClick: () => {
            handleCancel();
            setOpen(false);
          },
          label: t("modals:stop-refueling-automatically"),
          danger: true,
          className: hasAutomaticBestilling ? "" : "hidden",
        },
        {
          label: t("modals:form.buttons.setup"),
          onClick: () => form.submit(),
          loading,
        },
      ]}
    >
      <p className="text-text-primary text-center">
        <Trans t={t} i18nKey="modals:automatically-make.payment-under-a-limit">
          Foretag automatisk en betaling,
          <br /> når kredit er under en bestemt værdi.
        </Trans>
      </p>
      <Form
        className="my-8 "
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item
          name="refillBelow"
          label={t("modals:form.labels.refueling-under")}
          rules={[
            {
              message: t("translation:form.errors.required-a", {
                value: t("modals:values.min"),
              }),
              type: "number",
              min: 25,
              max: 1000,
            },
          ]}
          initialValue={user?.refillBelow}
        >
          <InputNumber
            min={25}
            max={1000}
            className="w-full"
            placeholder="50"
          />
        </Form.Item>
        <Form.Item
          name="refillAmount"
          label={t("modals:form.labels.refueling-amount")}
          initialValue={user?.refillAmount}
          rules={[
            {
              message: t("translation:form.errors.required-a", {
                value: t("modals:values.filling"),
              }),
              type: "number",
              min: 75,
              max: 5000,
            },
          ]}
        >
          <InputNumber
            min={75}
            max={5000}
            className="w-full"
            placeholder="200"
          />
        </Form.Item>
      </Form>
    </DrawerOrModal>
  );
};

export default RefillSettings;
