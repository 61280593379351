import Head from "next/head";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";
import { useEffect } from "react";
import tinycolor from "tinycolor2";

const CompanySuppliers = () => {
  const { customBranding, setStatusBarStyle } = useContainer(AppContext);

  useEffect(() => {
    if (typeof window === "undefined" || !window?.["isRenderedInWebView"])
      return;

    if (!customBranding) {
      setStatusBarStyle("dark");
      return;
    }

    const appBackground = customBranding?.custom?.palette?.background?.primary;
    const isIOsTextReadableOnBg = tinycolor.isReadable(
      "#000000",
      appBackground
    );

    if (!isIOsTextReadableOnBg) {
      setStatusBarStyle("light");
      return;
    }

    setStatusBarStyle("dark");
  }, [customBranding, setStatusBarStyle]);

  return (
    <Head>
      <title>
        {customBranding?.name
          ? `${customBranding.name} | powered by Kanpla`
          : "Kanpla"}
      </title>
      <link rel="shortcut icon" href={customBranding?.favicon} />
    </Head>
  );
};

export default CompanySuppliers;
