import { OrderingContext } from "@kanpla/ordering";
import {
  db,
  fetchCollection,
  getPartnerUrl,
  useLocationHostname,
  useLocalStorage
} from "@kanpla/system";
import { OrderOrder, Supplier } from "@kanpla/types";
import { message } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import { useRouter } from "next/router";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../../components/contextProvider";

interface Props {
  mode: "order" | "credit" | "subscription";
  setReceiptTime?: Dispatch<SetStateAction<number>>;
  setCheckoutItems?: Dispatch<SetStateAction<OrderOrder>>;
  setReceiptOpen?: Dispatch<SetStateAction<boolean>>;
  callback?: () => void;
}

/**
 * Handles Reepay payments on external window
 * @param props
 * @returns
 */
const useWindowPayment = (props: Props) => {
  const { t, i18n } = useTranslation(["mealplan2", "translation", "payment"]);
  // Util to change the localization of moment.js
  moment.locale(i18n.language);
  const {
    mode: modeProp,
    setReceiptOpen,
    setCheckoutItems,
    setReceiptTime,
    callback = () => null,
  } = props;
  const { loadCards, setBalance, balance, schoolId, moduleId, setIsSaving } =
    useContainer(AppContext);
  const {
    setShouldNotifyUserAfterRefill,
    basketContainerUtils,
    basketContainer,
    setOpenBasket,
  } = useContainer(OrderingContext);

  /** Indicate if the result was processed and reported to avoid double messages */
  const [reported, setReported] = useState(false);

  const router = useRouter();

  const [baseUrl, setBaseUrl] = useState(null);

  // Retrieve Reepay session id
  const [reepaySessionId, setReepaySessionId] =
    useLocalStorage("reepay-session-id");

  // Prevent handle function to be fired consecutively
  const [isBeingProcessed, setIsBeingProcessed] = useLocalStorage(
    "reepay-in-progress",
    false
  );

  const hostname = useLocationHostname({});

  const getHostDomain = useCallback(() => {
    const newUrl = getPartnerUrl({ hostname });
    const isKanpla = !newUrl || newUrl === "app";
    const domain = isKanpla ? "kanpla" : newUrl;
    return domain;
  }, [hostname]);

  const getUrl = useCallback(async () => {
    try {
      // Get potential supplier based on the app url
      const domain = getHostDomain();

      const suppliers = await fetchCollection<Supplier>(
        db.collection("suppliers").where("url", "==", domain)
      );

      if (isEmpty(suppliers)) return domain;

      const targetSupplier = suppliers?.find((s) => s?.isMain) || suppliers[0];
      const targetUrl = targetSupplier?.appUrl || targetSupplier?.url || domain;

      return targetUrl;
    } catch (err) {
      console.error(err);
    }
  }, [getHostDomain]);

  useEffect(() => {
    const domain = getHostDomain();
    if (typeof window === undefined || domain === undefined) return;

    (async () => {
      const partnerUrl = await getUrl();

      // @ts-ignore
      if (isMobile && window.isRenderedInWebView) {
        setBaseUrl(`${partnerUrl !== "kanpla" ? partnerUrl : "kanpla"}://`);
      } else {
        setBaseUrl(`${window.location.origin}/`);
      }
    })();
  }, [getHostDomain, getUrl]);

  const resetRouter = async () => {
    // remove queries from the url
    await router.replace(
      `${baseUrl}app/s/${schoolId}/m/${moduleId}`,
      undefined,
      { shallow: true }
    );
  };

  const handleReepayFallback = async () => {
    const {
      id: sessionId,
      invoice: invoiceId,
      payment_method: cardHandle,
      orderId: paymentId,
      cancel,
      mode,
      error,
    } = router.query;

    try {
      if (reported) return;
      if (!reepaySessionId) return;

      setIsBeingProcessed(true);
      setIsSaving(1);

      if (error)
        throw new Error(
          `${t("mealplan2:message.error.error-code", { value: error })}`
        );

      if (cancel && cancel === "true") {
        setIsBeingProcessed(false);
        await resetRouter();
        throw new Error(t("payment:message.your-payment-cancelled"));
      }

      if (!sessionId && !invoiceId) {
        setIsBeingProcessed(false);
        return;
      }

      if (mode === "order" && !paymentId) throw new Error("Incorrect mode");
      if (reepaySessionId !== sessionId)
        throw new Error("Session er ikke gyldig");

      // if payment is not settled from direct order, update the balance
      if (modeProp === "credit" && mode === "credit") {
        return;
      }

      if (mode === "order") {
        // Reset cross basket
        basketContainerUtils.reset();

        // // This is force-cleaning the basket! Should be refactored
        setTimeout(() => basketContainerUtils.reset(), 300);
        setTimeout(() => basketContainerUtils.reset(), 500);
        setTimeout(() => basketContainerUtils.reset(), 700);
        setTimeout(() => basketContainerUtils.reset(), 1000);

        callback();

        message.success({
          key: "payment-successful",
          content: t("translation:message.success.payment-successful"),
        });
      }

      if (!isEmpty(basketContainer)) setShouldNotifyUserAfterRefill(true);

      // store user card
      if (cardHandle) {
        await loadCards();
      }
    } catch (err) {
      message.error({
        key: "payment-cancelled",
        content: t("mealplan2:message.error.payment-failed", {
          value: err.message,
        }),
      });
      console.error(err);
      setOpenBasket(true);
    } finally {
      await resetRouter();
      setIsSaving(0);
      setReported(true);
      setReepaySessionId(null);
      setIsBeingProcessed(false);
    }
  };

  useEffect(() => {
    if (
      !schoolId ||
      !moduleId ||
      typeof setReceiptOpen === "undefined" ||
      typeof setCheckoutItems === "undefined" ||
      typeof setReceiptTime === "undefined" ||
      typeof setBalance === "undefined" ||
      typeof loadCards === "undefined"
    )
      return;

    if (
      Object.values(router?.query).length === 0 ||
      Boolean(isBeingProcessed) ||
      reepaySessionId === null
    ) {
      return;
    }

    // Since this hook gets called right after the refresh, some functions and values are still undefined
    // we first check that we have them available, depending on the mode of the payment made
    if (
      modeProp === "order" &&
      (typeof setReceiptOpen === "undefined" ||
        typeof setReceiptTime === "undefined" ||
        typeof setCheckoutItems === "undefined")
    )
      return;

    if (
      modeProp === "credit" &&
      (typeof setBalance === "undefined" ||
        typeof loadCards === "undefined" ||
        !balance)
    ) {
      return;
    }

    setOpenBasket(false);

    handleReepayFallback();
  }, [
    setReceiptOpen,
    setCheckoutItems,
    setReceiptTime,
    setBalance,
    loadCards,
    balance,
    schoolId,
    moduleId,
    router?.query,
    isBeingProcessed,
    reepaySessionId,
  ]);

  return {
    callbackUrl: `${baseUrl}app/s/${schoolId}/m/${moduleId}`,
  };
};

export default useWindowPayment;
