import { useWindowSize } from "@kanpla/system";
import { PopConfirmButton, TooltipDisabledButton } from "@kanpla/types";
import { ButtonProps } from "antd";
import classnames from "classnames";
import React, { ReactElement, ReactNode } from "react";
import { Lock } from "..";
import { Actions } from "../elements/BottomDrawer/Actions";
import { ModalWrapper } from "./ModalWrapper";

export interface ActionType
  extends ButtonProps,
    TooltipDisabledButton,
    PopConfirmButton {
  label?: string | ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  dataCy?: string;
}

export interface ModalProps {
  children: any;
  open: boolean;
  setOpen: (nextState: boolean) => void;
  title?: string;
  subtitle?: any;
  className?: string;
  style?: React.CSSProperties;
  containerClassName?: string;
  noPadding?: boolean;
  actions?: Array<ActionType>;
  zMax?: boolean;
  zIndex?: number;
  actionsClassName?: string;
  closableOutside?: boolean;
  disableLock?: boolean;
  maxWidth?: string;
  extraTopContent?: ReactElement;
  preventThroughClick?: boolean;
}

export const Modal = (props: ModalProps) => {
  const {
    children,
    open,
    setOpen,
    title,
    subtitle,
    className = "",
    style = {},
    containerClassName = "",
    actionsClassName = "",
    noPadding = false,
    actions,
    zMax,
    zIndex = 40,
    closableOutside = true,
    disableLock = false,
    maxWidth = "max-w-md",
    extraTopContent = null,
    preventThroughClick,
  } = props;

  const { width: screenWidth } = useWindowSize();

  return (
    <ModalWrapper
      containerClassName={classnames("py-16 flex px-3", containerClassName)}
      innerClassName={classnames(
        "rounded-lg bg-background-primary shadow-xl relative w-full max-h-full overflow-auto scrollbar-hide m-auto",
        maxWidth,
        className
      )}
      innerStyles={{
        transformOrigin: "50% 50% 0",
        ...style,
      }}
      open={open}
      setOpen={setOpen}
      dragToClose={true}
      zMax={zMax}
      zIndex={zIndex}
      closableOutside={closableOutside}
      preventThroughClick={preventThroughClick}
    >
      {!disableLock && screenWidth >= 768 && open && <Lock />}
      <>
        <div className={`${noPadding ? "p-0" : "p-8"} h-full`}>
          {extraTopContent}
          {title && (
            <div className="mb-4 relative z-10">
              <h2 className="h600 text-center">{title}</h2>
              {subtitle && (
                <h2 className="text-text-secondary text-center">{subtitle}</h2>
              )}
            </div>
          )}
          {children}
        </div>

        {actions && (
          <div
            className={`flex flex-wrap justify-center whitespace-nowrap w-full rounded-b-lg overflow-hidden
            sticky bottom-0 bg-gradient-to-b from-transparent to-background-primary z-20
              ${actionsClassName || ""}`}
          >
            <Actions actions={actions} />
          </div>
        )}
      </>
    </ModalWrapper>
  );
};

export default Modal;
