import { useEscapeKeyPress } from "@kanpla/system";
import React, { useEffect, useRef } from "react";
import { Portal } from "react-portal";
import { animated, config, useTransition } from "react-spring";

interface Props {
  children: any;
  open: boolean;
  setOpen: (nextState: boolean) => void;
  containerClassName?: string;
  innerClassName?: string;
  innerStyles?: any;
  dragToClose?: boolean;
  zMax?: boolean;
  fullHeight?: boolean;
  zIndex?: number;
  closableOutside?: boolean;
  /** This will add a `preventDefault` on the modal, in case there's a trigger we don't want to trigger */
  preventThroughClick?: boolean;
}

export const ModalWrapper = (props: Props) => {
  const {
    children,
    open,
    setOpen,
    containerClassName,
    innerClassName = "",
    innerStyles = {},
    zMax,
    fullHeight = false,
    zIndex = 40,
    closableOutside = true,
    preventThroughClick,
  } = props;

  const windowEl = useRef();
  const containerEl = useRef();

  useEscapeKeyPress(
    closableOutside && open ? () => setOpen(false) : () => null
  );

  const detectClick = (e: any) => {
    if (preventThroughClick) e.preventDefault();

    const scrollbar =
      e.offsetX > e.target.clientWidth || e.offsetY > e.target.clientHeight;
    const isOutside =
      // @ts-ignore
      windowEl?.current && !windowEl?.current?.contains(e.target);
    const closeIfOutside = closableOutside && isOutside;

    if (!scrollbar && closeIfOutside && open) {
      setOpen(false);
    }
  };

  useEffect(() => {
    const element = containerEl.current;
    if (!element) return;
    (element as any).addEventListener("mousedown", detectClick, false);
    return () => {
      (element as any).removeEventListener("mousedown", detectClick, false);
    };
  }, [containerEl, open]);

  const overlayTransitions = useTransition(open, {
    config: config.slow,
    from: { opacity: 0 },
    enter: { opacity: 0.4 },
    leave: { opacity: 0, pointerEvents: "none" },
  });

  const customConfig = { mass: 3, tension: 450, friction: 40 };

  const modalTransitions = useTransition(open, {
    config: customConfig,
    from: { opacity: 0, scale: 0.95, y: 20 },
    enter: { opacity: 1, scale: 1, y: 0 },
    leave: { opacity: 0, scale: 0.75, y: 20 },
  });

  return (
    <Portal>
      {overlayTransitions(
        (props, item) =>
          item && (
            <animated.button
              style={{ ...props, zIndex: zMax ? "999" : zIndex }}
              aria-label="close modal"
              className={`block bg-text-primary inset-0 fixed opacity-50 w-full cursor-default`}
            />
          )
      )}

      <div
        ref={containerEl}
        style={{ ...props, zIndex: zMax ? "999" : zIndex }}
        className={`fixed inset-0 overflow-x-hidden ${
          fullHeight ? "h-full" : ""
        } ${
          open
            ? "pointer-events-auto overflow-y-scroll"
            : "pointer-events-none overflow-y-hidden"
        } ${containerClassName}`}
      >
        {modalTransitions(
          ({ opacity, y }, item) =>
            item && (
              <animated.div
                ref={windowEl}
                style={{
                  opacity: opacity,
                  transform: y.to((y) => `translate3d(0,${y}px,0)`),
                  transformOrigin: "50% 50% 0",
                  height: fullHeight && "100%",
                  ...innerStyles,
                }}
                className={innerClassName}
              >
                {children}
              </animated.div>
            )
        )}
      </div>
    </Portal>
  );
};
