export type ImpactFeedbackStyle = "light" | "medium" | "heavy";
export type NotificationFeedbackType = "error" | "success" | "warning";

interface Props {
  /** The type of the feedback you want to create, use `notification` when wanting to give a feedback on operations such as `error` or `success` */
  type: "notification" | "impact";
  /** Used when interacting with UI blocks, defaults to `medium` intensity */
  impactFeedback?: ImpactFeedbackStyle;
  /** Used when giving feedbacks on operations, defaults to `success` */
  notificationFeedback?: NotificationFeedbackType;
}

/**
 * Create haptics feedback to be used for when users clicks on buttons, checkboxes etc.
 * or when wanting to give a feedback, like `success` or `error`
 *
 * !!! This only works on Apple devices !!!
 */
export const useHaptics = () => {
  const createHapticFeedback = (props: Props) => {
    try {
      if (typeof window === undefined || !window?.["isRenderedInWebView"])
        return;

      window?.["ReactNativeWebView"]?.postMessage(
        JSON.stringify({ createHaptic: props })
      );
    } catch (err) {
      console.error(err);
    }
  };

  return {
    createHapticFeedback,
  };
};
