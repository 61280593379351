import { faBell, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNotifications } from "@kanpla/system";
import "firebase/messaging";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";
import { message } from "antd";
import { generate } from "short-uuid";

const FCM = () => {
  const { user, auth, setNotificationBadge } = useContainer(AppContext);

  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState(null);

  const { notification: _notification } = useNotifications({
    user,
    auth,
    setNotificationBadge,
  });

  useEffect(() => {
    (async () => {
      try {
        if (typeof window === "undefined") return;

        setNotification(_notification);
        setOpen(true);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [_notification]);

  useEffect(() => {
    if (!open) return;
    if (!notification) return;
    const { title, body } = notification;

    const messageKey = generate();

    message.success({
      key: messageKey,
      content: (
        <div className="w-full flex justify-between items-center">
          <div className="w-full flex flex-col pr-2">
            <h3 className="font-semibold">{title}</h3>
            <p className="mt-1 text-sm">{body}</p>
          </div>
          <FontAwesomeIcon
            icon={faTimes}
            className="hover:opacity-70 cursor-pointer ml-2"
            onClick={() => {
              message.destroy(messageKey);
              setNotification(null);
            }}
          />
        </div>
      ),
      icon: (
        <FontAwesomeIcon
          icon={faBell}
          size="1x"
          className="text-primary-main pl-4"
        />
      ),
      duration: 0,
      style: { padding: "2px 4px" },
    });
  }, [open, notification]);

  return <></>;
};

export default FCM;
