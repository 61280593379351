import { callInternalApi } from "@kanpla/system";
import { useCallback, useMemo } from "react";
import useSWR, { SWRConfiguration, useSWRConfig } from "swr";
import { APICallOptions } from "./callInternalApi";

interface Config<RequestData, ResponseType>
  extends SWRConfiguration,
    APICallOptions {
  customFetcher?: (req: RequestData) => Promise<ResponseType>;
}

export const useFetch = <RequestData extends Record<any, any>, ResponseType>(
  /** path of the function inside the /api folder (e.g. "offers/loadFrontend") */
  path: string,
  /** Data passed as props to the api */
  requestData: RequestData,
  /** Extra configuration for the fetching, follows SWR config */
  config: Config<RequestData, ResponseType> = {}
  /** Custom fetcher to overwrite callInternalApi */
) => {
  const { mutate } = useSWRConfig();
  const fullKey = useMemo(
    () => ({ ...requestData, path }),
    [path, requestData]
  );

  type Fetcher = (req: RequestData) => Promise<ResponseType>;

  const hasCustomFetcher = typeof config?.customFetcher !== "undefined";
  const fetcher: Fetcher = useCallback(
    async (keys: RequestData) => {
      if (hasCustomFetcher) return await config?.customFetcher(keys);
      return await callInternalApi<RequestData, ResponseType>(
        path,
        keys,
        config
      );
    },
    [hasCustomFetcher, config, path]
  );

  // Add path to make it unique
  // @ts-ignore
  requestData.path = path;

  // Fetch
  const { data, isValidating } = useSWR<ResponseType>(fullKey, fetcher, {
    refreshInterval: 10 * 60 * 1000,
    ...config,
  });

  // Update
  const setData = useCallback(
    <NewDataType>(
      /** Data as your expect to receive it from the updater */
      newData: NewDataType,
      /** The actual updater ('submit' from useSubmit hook) */
      updater?: () => Promise<NewDataType>,
      /** Transforms the data into a partial that can be merge with the current data */
      dataTransformer?: (
        newData: NewDataType,
        optimistic?: boolean
      ) => Partial<ResponseType>
    ) => {
      const mergeData = (partial: NewDataType, optimistic: boolean) => ({
        ...data,
        ...(typeof dataTransformer === "function"
          ? dataTransformer(partial, optimistic)
          : newData),
      });

      const fullUpdater = async () => {
        const result =
          typeof updater === "function" ? await updater() : newData;
        return mergeData(result, false);
      };

      mutate(fullKey, fullUpdater, {
        optimisticData: mergeData(newData, true),
        revalidate: false,
        // populateCache,
        rollbackOnError: true,
      });
    },
    [data, fullKey, mutate]
  );

  // Loading
  // const dataTrigger = JSON.stringify(data);
  // const defaultDataString = JSON.stringify(config.fallbackData);
  // const isDataDefault = dataTrigger === defaultDataString;
  // const invalidData = !data || isDataDefault;
  // const loading = isValidating && invalidData;
  const loading = !data;

  return { data, isValidating, setData, loading };
};
