import { Module } from "@kanpla/types";
import { useEffect, useState } from "react";

const UseModules = ({ modules = [] }: { modules: Module[] }) => {
  // Current module in view
  const [moduleId, setModuleId] = useState<string | null>(null);
  const [previousModuleId, setPreviousModuleId] = useState<string | null>(null);
  const module = modules.find((m) => m.id === moduleId) || modules[0];

  // Default load moduleId
  useEffect(() => {
    if (module?.id && !moduleId) setModuleId(module?.id);
    return () => (module?.id ? setPreviousModuleId(module.id) : null);
  }, [module?.id, moduleId]);

  return { modules, module, moduleId, setModuleId, previousModuleId };
};

export default UseModules;
