import { AllLanguages, School, Supplier, User } from "@kanpla/types";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocalstorageState } from "rooks";
import { db } from "../firebase.config";
import { checkLanguage, getLanguageLevel } from "./checkLanguage";
import { LANGUAGES } from "./constants";

type Props = {
  school?: School;
  user?: User;
  customBranding?: Supplier;
};

type Return = {
  allLanguages?: AllLanguages[];
  getLanguage?: (
    id: string,
    array?: School[],
    type?: string
  ) => { language: string } | { language?: undefined } | Promise<any>;
  localeFrom: string;
  setLocaleFrom: Dispatch<SetStateAction<string>>;
};

export const UseLocale = (props?: Props): Return => {
  const { i18n } = useTranslation(["libs"]);

  const [localeFrom, setLocaleFrom] = useLocalstorageState("locale_from", "");

  const allLanguages = useMemo(
    () => [
      { label: "Dansk", value: "da", icon: "🇩🇰" },
      { label: "English", value: "en", icon: "🇬🇧" },
    ],
    []
  );

  const updateTranslations = () => {
    if (props) {
      const currentLanguageObj = checkLanguage({
        ...props,
        languages: LANGUAGES,
      });
      // Only updates if the level is equal or higher (User > School > Supplier > default)
      const newLanguageLevel = getLanguageLevel(currentLanguageObj.from);
      const savedLanguageLevel = getLanguageLevel(localeFrom);
      const updateComesFromHigherLevel = newLanguageLevel >= savedLanguageLevel;
      if (
        updateComesFromHigherLevel ||
        currentLanguageObj.language !==
          window.localStorage.getItem("i18nextLng")
      ) {
        setLocaleFrom(currentLanguageObj.from);
        i18n.changeLanguage(currentLanguageObj.language);
      }
    }
  };

  const propsTrigger = useMemo(
    () => JSON.stringify(props),
    [JSON.stringify(props)]
  );
  useEffect(() => {
    updateTranslations();
  }, [propsTrigger]);

  const getLanguage = async (id: string, array?: School[], type?: string) => {
    switch (type) {
      case "suppliers": {
        try {
          const querySnapshot = await db
            .collection("suppliers")
            .where("partnerId", "==", id)
            .get();
          return querySnapshot.docs[0].data();
        } catch (error) {
          console.log("~ system/src/i18n/UseLocale.tsx ~", error);
          return {};
        }
      }
      default: {
        const item = array.find((item) => item.id === id);
        if (item && item.language) {
          return { language: item.language };
        }
        return {};
      }
    }
  };

  return {
    allLanguages,
    getLanguage,
    localeFrom,
    setLocaleFrom,
  };
};
