import { Order, OrderOrder, OrderOrderProduct } from "@kanpla/types";
import { calculateConfigTotal } from "./calculateConfigTotal";

/** Calculates the price of the total order, including variants, **doesn't include discounts**! */
export const calculateOrderTotal = (order: OrderOrder) =>
  Object.values(order || {}).reduce(
    (orderAcc: number, i: OrderOrderProduct) => {
      // Calculate extra price for configurations
      const configPrice =
        Object.values(i?.config || {})?.reduce((configAcc, config) => {
          // The extra price of one portion of certain config
          const optionsPrice = calculateConfigTotal(config) || 0;
          return configAcc + optionsPrice * (config.amount || 0);
        }, 0) || 0;

      // The total price of all the portions of the product
      const productPrice = (i?.price || 0) * (i?.amount || 0) + configPrice;

      return orderAcc + productPrice;
    },
    0
  );

export interface OrderInvoiceTotalResponse {
  withoutVat: number;
  vat: number;
  withVat: number;
}

export const calculateOrderInvoiceTotal = (
  order: Order
): OrderInvoiceTotalResponse => {
  const orderTotal = calculateOrderTotal(order.order);

  const vatPercentage = order.paymentMethod === "credit" ? 0.2 : 0.25;
  const vatTotal = orderTotal * vatPercentage;

  const totalWithoutVat =
    order.paymentMethod === "credit" ? orderTotal - vatTotal : orderTotal;
  const totalWithVat = totalWithoutVat + vatTotal;

  return {
    withoutVat: totalWithoutVat,
    vat: vatTotal,
    withVat: totalWithVat,
  };
};
