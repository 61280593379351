import {
  getActivePlugins,
  useBasket,
  useCalendarFormatter,
  useWindowSize,
} from "@kanpla/system";
import {
  Child,
  CombinedOfferItem,
  LoadOfferReturn,
  Module,
  School,
  Supplier,
  TimeNavigation,
  Timestamp,
  User,
  Week,
} from "@kanpla/types";
import moment from "moment";
import { Dispatch, SetStateAction, useMemo } from "react";
import { isMobile } from "react-device-detect";
import { createContainer } from "unstated-next";

interface Props {
  week: Week;
  school: School;
  innerAppLoading: boolean;
  child: Child;
  user: User;
  module: Module;
  date: Timestamp;
  timeNavigation: TimeNavigation;
  setTimeNavigation: Dispatch<SetStateAction<TimeNavigation>>;
  offer: LoadOfferReturn;
  isBulk: boolean;
  setIsBulk: Dispatch<SetStateAction<boolean>>;
  supplier?: Supplier;
  dayIndex: number;
  modules: Array<Module>;
  /** Gives us different / multiple options if we're using admin UI */
  fromAdmin?: boolean;
  /** Fill only on backend if `fromAdmin === true` */
  adminId?: string;
}

const ContextState = (props: Props = {} as Props) => {
  const childId = props?.child?.id;
  const userId = props?.user?.id;
  const moduleId = props?.module?.id;
  const schoolId = props?.school?.id;

  const calendarFormatter = useCalendarFormatter();
  const { width: screenWidth } = useWindowSize();
  const mobile = isMobile || (screenWidth || 0) < 768;

  const activePlugins = getActivePlugins({
    module: props?.module,
    schoolId,
    groupName: props?.child?.groupName,
  });

  const displayDate = useMemo(
    () =>
      moment
        .unix(props?.week?.[props?.dayIndex]?.seconds || 0)
        .calendar(null, calendarFormatter),
    [calendarFormatter, props?.dayIndex, props?.week]
  );

  const defaultReference = props?.child?.defaultReference;

  const hasRequiredProduct = useMemo(() => {
    const requiredProduct = props?.module?.plugins?.requiredProduct;

    return (
      requiredProduct?.active &&
      (props?.offer?.items as Array<CombinedOfferItem>)?.some(
        (p) => p.productId === requiredProduct?.productId
      )
    );
  }, [props?.module?.plugins?.requiredProduct, props?.offer?.items]);

  const {
    basket,
    setBasket,
    openBasket,
    setOpenBasket,
    basketContainerUtils,
    basketContainer,
    setBasketContainer,
    shouldNotifyUserAfterRefill,
    setShouldNotifyUserAfterRefill,
  } = useBasket({
    userId,
    module: props?.module,
    schoolId,
    childId,
    dateSeconds: props?.date?.seconds,
    fromAdmin: props?.fromAdmin,
  });

  return {
    ...props,
    childId,
    userId,
    moduleId,
    schoolId,
    mobile,
    activePlugins,
    displayDate,
    defaultReference,
    hasRequiredProduct,
    basket,
    setBasket,
    openBasket,
    setOpenBasket,
    basketContainerUtils,
    basketContainer,
    setBasketContainer,
    shouldNotifyUserAfterRefill,
    setShouldNotifyUserAfterRefill,
  };
};

export const OrderingContext = createContainer(ContextState);
