import { useState } from "react";
import { APIError } from "../errors/api";
import { callInternalApi } from "./callInternalApi";

export const useRequest = <RequestData, ResponseType>(
  /** path of the function inside the /api folder (e.g. "offers/loadFrontend") */
  path: string,
  callback?: (res: ResponseType) => Promise<void> | void
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const request = async (data: RequestData) => {
    try {
      setIsSubmitting(true);

      const res: ResponseType = await callInternalApi(path, data);

      if (callback) await callback(res);
      return res;
    } catch (err) {
      throw new APIError(err?.status || 500, err?.message);
      // TODO: Handle error
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return { request, isSubmitting };
};
