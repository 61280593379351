import { message } from "antd";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { APIError } from "../errors/api";

export interface APICallOptions {
  /** Hide error message, in case you want to handle the error in your own way */
  noErrorMessage?: boolean;
}

export const callInternalApi = async <RequestData, ResponseType>(
  path: string,
  data: RequestData,
  options?: APICallOptions
) => {
  const auth = getAuth();
  const user = auth?.currentUser;
  const token = await user?.getIdToken();
  try {
    if (!window) return null;

    const hostname = window.location.hostname;
    const isLocalhost = hostname === "localhost";
    const isVercelTest = hostname?.includes("vercel.app");
    const res = await axios({
      method: "POST",
      url: `/api/internal/${path}`,
      headers: {
        Authorization: "Bearer " + token,
        "kanpla-auth-provider": "GAuth",
        "kanpla-debug": true,
        "kanpla-app-env": isLocalhost ? "DEV" : isVercelTest ? "TEST" : "PROD",
      },
      data: data || {},
    });

    return res.data as ResponseType;
  } catch (e) {
    const status = e?.response?.status || 500;
    const msg = e?.response?.data?.message;

    console.error("callInternalAPI code", status);
    console.error("callInternalAPI message", msg);

    if (!status || !msg) console.error(e);

    if (!(options?.noErrorMessage ?? true)) {
      message.error({ key: "error-message", content: e?.message });
    }
    throw new APIError(status, msg);
  }
};
