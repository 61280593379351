import { OrderingContext } from "@kanpla/ordering";
import { FC } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

const OrderingContextWrapper: FC = ({ children }) => {
  const {
    week,
    school,
    innerAppLoading,
    child,
    user,
    module,
    date,
    timeNavigation,
    setTimeNavigation,
    offer,
    isBulk,
    setIsBulk,
    supplier,
    dayIndex,
    modules,
  } = useContainer(AppContext);

  return (
    <OrderingContext.Provider
      initialState={{
        week,
        school,
        innerAppLoading,
        child,
        user,
        module,
        date,
        timeNavigation,
        setTimeNavigation,
        offer,
        isBulk,
        setIsBulk,
        supplier,
        dayIndex,
        modules,
      }}
    >
      {children}
    </OrderingContext.Provider>
  );
};

export default OrderingContextWrapper;
