type Props = {
  hostname: string;
};

export const getPartnerUrl = ({ hostname = "" }: Props) => {
  // 1. Clean and split hostname by '.'
  if (typeof hostname !== "string") return undefined;
  const cleanHostname = hostname.replace("www.", "");
  const strings = cleanHostname.split(".");

  // 2. Reject if not formatted as **.kanpla.**
  const kanplaIndex = strings.indexOf("kanpla");
  const isKanplaDomain = strings.includes("kanpla");
  if (!isKanplaDomain) return undefined;

  // 3. Extract subdomain
  const subdomainIndex = kanplaIndex - 1;
  const subdomain = strings[subdomainIndex];

  // 4. Reject if subdomain not valid string
  if (!subdomain || subdomainIndex < 0) return undefined;

  return subdomain;
};
