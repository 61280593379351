import { db, FieldValue } from "@kanpla/system";
import { message } from "antd";
import { useContainer } from "unstated-next";
import { AppContext } from "../components/contextProvider";

const useCancelRefill = () => {
  const { user, userId } = useContainer(AppContext);

  const hasActiveRefill =
    user &&
    typeof user?.refillBelow === `number` &&
    typeof user?.refillAmount === `number`;

  const handleCancel = async () => {
    if (!hasActiveRefill) return;

    const data = {
      refillBelow: FieldValue.delete(),
      refillAmount: FieldValue.delete(),
      refillLast: FieldValue.delete(),
      refillError: FieldValue.delete(),
      refilling: FieldValue.delete(),
    };

    await db
      .collection("users")
      .doc(userId)
      .update(data)
      .then(() => {
        message.success(`Automatisk optanking stoppes`);
        return;
      })
      .catch((error) => {
        message.error(error.message);
        return;
      });
  };

  return { handleCancel };
};

export default useCancelRefill;
