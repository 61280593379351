import {
  addWeek,
  getCurrentWeek,
  getWeekArray,
  getWeekSeconds,
  Timestamp,
  useCalendarFormatter,
} from "@kanpla/system";
import {
  DayIndex,
  TimeNavigation,
  Timestamp as TimestampType,
  Week,
} from "@kanpla/types";
import moment from "moment";
import { NumberParam, useQueryParam } from "next-query-params";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";

export const UseTimeNavigation = () => {
  const router = useRouter();

  const [timeNavigation, setTimeNavigation] =
    useState<TimeNavigation>("todaySwitch");
  const todayIndex = (moment().day() - 1) as DayIndex;

  const currentWeek = getCurrentWeek({ Timestamp });
  const isWeekend = ![0, 1, 2, 3, 4].includes(todayIndex);

  const [week, setWeek] = useState<Week>(
    isWeekend ? addWeek(currentWeek) : currentWeek
  );
  const [dayIndex, setDayIndex] = useState<DayIndex>(
    isWeekend ? 0 : todayIndex
  ); // Can be 0,1,2,3,4
  const date = week[dayIndex];
  const dateSeconds = date?.seconds;
  const setDate = (newDate: TimestampType) => {
    const jsDate = new Date(newDate.seconds * 1000);
    let newDateIndex = (jsDate.getDay() - 1) as DayIndex;
    if (typeof newDateIndex !== `number`) return;
    if (newDateIndex > 4) newDateIndex = 4;
    if (newDateIndex < 0) newDateIndex = 0;
    if (setDayIndex) setDayIndex(newDateIndex);

    // Adjust week
    const newWeek = getWeekArray(getWeekSeconds(newDate.seconds), Timestamp);
    setWeek(newWeek);
  };

  const [paramDateSeconds, setParamDateSeconds] = useQueryParam(
    "date",
    NumberParam
  );

  useEffect(() => {
    if (!router.isReady) return;
    if (!paramDateSeconds) return;
    if (paramDateSeconds === dateSeconds) return;
    setDate(Timestamp.fromMillis(paramDateSeconds * 1000));
  }, [paramDateSeconds, router.isReady]);

  useEffect(() => {
    // Wait for the router to be ready before resetting the URL
    if (!router.isReady) return;
    if (paramDateSeconds === dateSeconds) return;
    setParamDateSeconds(dateSeconds);
  }, [dateSeconds, router.isReady]);

  const calendarFormatter = useCalendarFormatter();

  const displayDate = useMemo(
    () =>
      moment
        .unix(week[dayIndex]?.seconds || 0)
        .calendar(null, calendarFormatter),
    [calendarFormatter, dayIndex, week]
  );

  return {
    week,
    setWeek,
    dayIndex,
    setDayIndex,
    date,
    setDate,
    dateSeconds,
    calendarFormatter,
    displayDate,
    timeNavigation,
    setTimeNavigation,
  };
};
