import { useEffect, useState } from "react";
// import { getDeveloperVariables } from "@kanpla/system";

interface Props {
  defaultValue?: string | null;
}

export const maskedURLs = {
  "app.madklubben.dk": "506.kanpla.dk",
  "app.eaty.nu": "506.kanpla.dk",
  "mono-frontend-git-five-dev-kanpla.vercel.app": "506.kanpla.dk",
  "app.foodbycoor.dk": "coor.kanpla.dk",
  "app.kokkeneskoekken.dk": "kokkeneskoekken.kanpla.dk",
  "app.cheval-blanc.dk": "chevalblanc.kanpla.dk",
};

export const useLocationHostname = ({ defaultValue = "kanpla.dk" }: Props) => {
  const [hostname, setHostname] = useState(
    typeof window === "undefined"
      ? defaultValue
      : window?.sessionStorage?.getItem("kanplaLocationHostname")
  );

  useEffect(() => {
    const fromWindow = window.location.hostname;
    const fromStorage = window?.sessionStorage?.getItem(
      "kanplaLocationHostname"
    );

    // const supplierUrl = getDeveloperVariables()?.supplierUrl;

    const url =
      // maskedURLs[supplierUrl || fromWindow] ||
      // supplierUrl ||
      maskedURLs[fromWindow] || fromStorage || fromWindow;

    setHostname(url);
  }, []);

  return hostname;
};
