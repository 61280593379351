// Hook (use-auth.js)
import { auth, fb } from "@kanpla/system";
import {
  applyActionCode,
  confirmPasswordReset as authConfirmPasswordReset,
  fetchSignInMethodsForEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut as signOutFB,
  verifyPasswordResetCode,
} from "firebase/auth";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocalStorage } from "../hooks/useLocalStorage";

export interface UserAuth {
  user: firebase.default.User;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<firebase.default.User>;
  signUp: (email: string, password: string) => Promise<firebase.default.User>;
  signOut: () => Promise<void>;
  sendPasswordResetEmail: (email: string) => Promise<boolean>;
  confirmPasswordReset: (code: string, password: string) => Promise<boolean>;
  changePassword: (code: string, password: string) => Promise<void>;
  isUserEmailValid: (email: string) => Promise<boolean>;
  verifyEmail: (code: string) => Promise<void>;
  signInWithToken: (token: string) => Promise<firebase.default.User>;
}

interface Props {
  updateIntercomUser?: (userObj: { userId: string; email: string }) => void;
}

// Provider hook that creates auth object and handles state
export const UseAuth = (props: Props = {}) => {
  const { updateIntercomUser = null } = props;

  const [user, loading] = useAuthState(auth);
  const [, setLoggedIn] = useLocalStorage("user_logged_in", false);

  useEffect(() => {
    if (user) fb.analytics().setUserId(user.uid);

    updateIntercomUser?.({
      userId: user?.uid || "",
      email: user?.email || "",
    });
  }, [updateIntercomUser, user, user?.uid]);

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signIn = async (email: string, password: string) => {
    const response = await signInWithEmailAndPassword(auth, email, password);
    setLoggedIn(true);
    //@ts-ignore
    window.dataLayer.push({ loggedIn: true });
    return response.user;
  };

  const signInWithToken = async (token: string) => {
    const response = await signInWithCustomToken(auth, token);
    setLoggedIn(true);
    //@ts-ignore
    window.dataLayer.push({ loggedIn: true });
    return response.user;
  };

  const signUp = async (email, password) => {
    return fb
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        setLoggedIn(true);
        return response.user;
      });
  };

  const signOut = async () => {
    await signOutFB(auth);
    //@ts-ignore
    window.dataLayer.push({ loggedIn: false });
    setLoggedIn(false);
  };

  const sendPasswordResetEmail = async (email) => {
    return fb
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = async (code, password) => {
    return fb
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  const changePassword = async (code: string, password: string) => {
    try {
      await verifyPasswordResetCode(auth, code);
      return await authConfirmPasswordReset(auth, code, password);
    } catch (err) {
      throw new Error(err);
    }
  };

  const isUserEmailValid = async (email: string) => {
    try {
      const res = await fetchSignInMethodsForEmail(auth, email);
      if (!res.length) throw new Error();
      return true;
    } catch (err) {
      throw new Error(err);
    }
  };

  const verifyEmail = async (code) => {
    return await applyActionCode(auth, code);
  };

  // Return the user object and auth methods
  return {
    user,
    loading,
    signIn,
    signUp,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
    changePassword,
    isUserEmailValid,
    verifyEmail,
    signInWithToken,
  } as UserAuth;
};
