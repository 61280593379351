import { registerServiceWorker } from "../utils/registerServiceWorker";
import { urlBase64ToUint8Array } from "../utils/urlBase64ToUint8Array";
import { callInternalApi } from "../api/callInternalApi";
import { fb } from "../firebase.config";

const useWebPushNotifications = (userId: string) => {
  const publicVapidKey =
    "BL4Ow0pGc7zqpf7khzyatdibmg-UU8QA5sluRKzSReBVz7BJPUj52xDL4YKjbuSdAytMma0iF8VoKECJm9vmSVs";

  const doesBrowserSupportsPush = () =>
    "serviceWorker" in navigator && "PushManager" in window;

  const askPermissions = async () => {
    try {
      const result = await Notification.requestPermission();
      if (result !== "granted") throw new Error("Permissions weren't granted.");

      return true;
    } catch (err) {
      console.error(err);
    }
  };

  const checkForExistingSubscription = async (
    registration: ServiceWorkerRegistration
  ) => {
    try {
      return await registration.pushManager.getSubscription();
    } catch (err) {
      console.error(err);
    }
  };

  const subscribeUserToPush = async () => {
    try {
      // 1. Check browser support
      const supported = doesBrowserSupportsPush();
      if (!supported)
        throw new Error("This broswer does not support Push Notifications.");

      // 2. Register service worker
      const existingRegistration =
        await navigator.serviceWorker.getRegistration(
          "/push-notifications-sw.js"
        );

      if (!existingRegistration) {
        const registrationReady = await registerServiceWorker(
          "/push-notifications-sw.js"
        );
        if (!registrationReady)
          throw new Error("Service worker can't be registered.");
      }

      const registration = await navigator.serviceWorker.ready;

      const existingSubscription = await checkForExistingSubscription(
        registration
      );

      if (existingSubscription) {
        // If the subscriptions exists already, let's check if it's expired or not
        const subscriptionHasExpired = await callInternalApi(
          "user/isWebPushSubscriptionExpired",
          {
            pushSubscription: existingSubscription,
          }
        );

        if (!subscriptionHasExpired) return;

        // Unsubscribe the user if has expired, so we can fetch a new one
        await existingSubscription?.unsubscribe();
      }

      // 3. Ask for permissions
      const allowed = await askPermissions();
      if (!allowed) return;

      // 4. Subscribe user
      const options: PushSubscriptionOptionsInit = {
        // User must see when a notification is received, silent ones are prohibited!
        userVisibleOnly: true,
        // This is the application public key, also called VAPID key
        // see: https://web.dev/push-notifications-subscribing-a-user/#applicationserverkey-option
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
      };

      const pushSubscription: PushSubscription =
        await registration.pushManager.subscribe(options);

      // Store push subscrition to db
      // notifications-storeUserPushSubscription
      await callInternalApi("user/submitWebPushSubscription", {
        userId,
        pushSubscription: JSON.stringify(pushSubscription),
      });

      return pushSubscription;
    } catch (err) {
      console.error(err);
    }
  };

  const retrieveFCMToken = async () => {
    try {
      // 1. Check browser support
      const supported = doesBrowserSupportsPush();
      if (!supported)
        throw new Error("This broswer does not support Push Notifications.");

      // 2. Ask for permissions
      const allowed = await askPermissions();
      if (!allowed) return;

      // 3. Request token
      const token = await fb?.messaging()?.getToken({
        vapidKey:
          "BM8oW0rMqfxtDWFXDtLoAT9JyiOjmZyxU0YHlyUMVy7jeTkCiYEyTmDswgOoJAIeMZ1mOZxnL-b0vZYS4Ru5W3U",
      });

      return token;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  return {
    retrieveFCMToken,
    doesBrowserSupportsPush,
  };
};

export default useWebPushNotifications;
