import {
  getPartnerUrl,
  Timestamp,
  useFetch,
  useLocationHostname,
} from "@kanpla/system";
import { changeAntdTheme } from "mini-dynamic-antd-theme";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { useLocalstorageState } from "rooks";
import {
  LoadFrontendProps,
  LoadFrontendResult,
} from "../pages/api/internal/offers/loadFrontend";

type Props = {
  userId: string;
};

interface FallBackDataProps extends LoadFrontendResult {
  loading?: boolean;
}

const fallbackData: FallBackDataProps = {
  supplier: null,
  user: null,
  isSuperadmin: false,
  card: null,
  cards: [],
  children: [],
  schools: [],
  /** Currently active */
  child: null,
  /** Currently active */
  school: null,
  /** Currently active */
  allModules: [],
  /** Currently active */
  modules: [],
  /** Currently active */
  offers: {},
  loading: true,
};

const useLoadFrontend = ({ userId }: Props) => {
  const [initialLoading, setInitialLoading] = useState(true);

  const router = useRouter();

  const [trigger, setTrigger] = useState(0);

  // Props
  const parseResult = useLocationHostname({ defaultValue: undefined });
  const url = getPartnerUrl({ hostname: parseResult });
  const [selectedChildId, setSelectedChildId] =
    useLocalstorageState<string>("current_child_id");

  const slugsLocal = router.query?.slugs as unknown as Array<string>;
  const schoolId = useMemo(() => {
    let schoolIdFromSlugs = null;

    (slugsLocal || []).forEach((tag: string, index: number) => {
      const nextValue = slugsLocal[index + 1];

      // SchoolID (s)
      if (tag === "s") {
        schoolIdFromSlugs = nextValue;
      }
    });

    return schoolIdFromSlugs;
  }, [slugsLocal]);

  // Load frontend data only if the user is really logged in
  const userLoggedIn = router?.asPath?.includes("/app");

  // Fetch
  const { data, isValidating, setData } = useFetch<
    LoadFrontendProps & { trigger: number },
    LoadFrontendResult
  >(
    "offers/loadFrontend",
    {
      userId: userLoggedIn && userId,
      selectedChildId:
        selectedChildId === "undefined" || !userLoggedIn
          ? undefined
          : selectedChildId,
      schoolId: !userId && userLoggedIn && schoolId,
      url,
      trigger,
    },
    {
      fallbackData,
      refreshInterval: 60000,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Only retry up to 3 times, then show modal
        // if (retryCount >= 3) {
        //   Modal.error({
        //     title: t("translation:message.error.could-not-load"),
        //     okText: t("translation:message.error.try-again"),
        //     onOk: () => revalidate({ retryCount }),
        //   });
        // }

        if (retryCount > 5) {
          setTimeout(() => revalidate({ retryCount }), 15000);

          return;
        }
        // Retry after 5 seconds.
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
    }
  );
  const state = data || fallbackData;
  const loading =
    (isValidating && !data.modules?.length) ||
    (data as FallBackDataProps)?.loading;

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  // Branding
  const { supplier } = state;

  const color =
    supplier?.custom?.palette?.primary?.main ||
    supplier?.custom?.color?.primary?.default ||
    supplier?.color;

  useEffect(() => {
    changeAntdTheme(color || "#48BB78");
  }, [color]);

  // Load offer
  const getOffer = (moduleId: string) => {
    const offer = state?.offers?.[moduleId];

    if (!offer) {
      return {
        items: [],
        holidayDates: {},
        deadlineInfo: {
          deadline: 27000,
          deadlineSoft: 27000,
          deadlineWeekRelative: false,
          deadlineExcludingWeekends: false,
          defaultDateSeconds: null,
          defaultSoftDateSeconds: null,
        },
        mealOptions: [],
      };
    }

    return {
      ...offer,
      deadlineInfo: {
        ...offer.deadlineInfo,
        defaultDate: new Timestamp(offer.deadlineInfo.defaultDateSeconds, 0),
        defaultSoftDate: new Timestamp(
          offer.deadlineInfo.defaultSoftDateSeconds,
          0
        ),
      },
    };
  };

  return {
    ...state,
    setChildId: setSelectedChildId,
    getOffer,
    loading,
    initialLoading,
    triggerReload: () => setTrigger((t) => t + 1),
    setData,
  };
};

export default useLoadFrontend;
