import { useFetch } from "./useFetch";
import { useRequest } from "./useRequest";

type SetData = ReturnType<typeof useFetch>["setData"];

interface Props<
  RequestData,
  ResponseType,
  SetDataType extends SetData = SetData
> {
  /** path of the function inside the /api folder (e.g. "offers/loadFrontend") */
  path: string;
  /** Function from useFetch to optimistically update data */
  setData?: SetData;
  /** Construct request to the server from submitted data */
  requestConstructor: (newData: ResponseType) => RequestData;
  /** Take response from the server (must have same format as submitted data), and update state with it */
  responseDestructor: (
    response: ResponseType
  ) => ReturnType<Parameters<SetDataType>[2]>;
}

export const useSubmit = <
  RequestData,
  ResponseType,
  SetDataType extends SetData = SetData
>(
  props: Props<RequestData, ResponseType, SetDataType>
) => {
  const {
    path,
    setData = () => null,
    requestConstructor,
    responseDestructor,
  } = props;

  // Actual call to the server
  const { request, isSubmitting } = useRequest<RequestData, ResponseType>(path);

  // Wrapped as an optimistic update
  const submit = async (submitData: ResponseType) => {
    const serverRequestLoad = requestConstructor(submitData);

    /** Returning the response allows us to do callback functions and get the response result if needed outside of handling the state */
    const response = request(serverRequestLoad);
    const awaitedResponse = await response;

    setData<ResponseType>(
      submitData,
      async () => awaitedResponse,
      responseDestructor
    );

    return awaitedResponse;
  };

  return { submit, isSubmitting };
};
