import { TooltipDisabledButton, PopConfirmButton } from "@kanpla/types";
import { Button, ButtonProps, Tooltip, Popconfirm } from "antd";
import classnames from "classnames";
import { cloneDeep } from "lodash";
import React, { ReactNode, useState } from "react";

export interface ActionButtonProps
  extends ButtonProps,
    TooltipDisabledButton,
    PopConfirmButton {
  className: string;
  label: string | ReactNode;
  onClick: () => Promise<void>;
  key?: string;
  dataCy?: string;
  autoWidth?: boolean;
}

export const ActionButton = (props: ActionButtonProps) => {
  const {
    onClick,
    label,
    className,
    type = "primary",
    dataCy = "",
    tooltip,
    autoWidth = false,
    popConfirm,
    ...otherProps
  } = props;

  const filteredProps = cloneDeep(props);
  delete filteredProps?.dataCy;

  const [loading, setLoading] = useState(false);

  const _onClick = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  if (popConfirm) {
    return (
      <Popconfirm {...popConfirm}>
        <Button size="large" type="primary" className="w-full">
          {label}
        </Button>
      </Popconfirm>
    );
  }

  if (tooltip?.hasTooltip) {
    return (
      <Tooltip title={tooltip?.title}>
        <Button
          type={type}
          size="large"
          className={`w-full flex-1 ${className || ""}`}
          style={tooltip?.style}
          {...props}
        >
          {label}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button
      type={type}
      size="large"
      onClick={_onClick}
      loading={loading}
      className={classnames(
        "flex-1",
        autoWidth ? "w-fit" : "w-full",
        className
      )}
      data-cy={dataCy}
      {...otherProps}
    >
      {label}
    </Button>
  );
};
