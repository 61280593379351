import classNames from "classnames";
import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import Imgix from "react-imgix";

interface Props {
  className?: string;
  src: string;
  alt: string;
  style?: CSSProperties;
  loadedAnyway?: boolean;
  dataCy?: string;
  size?: {
    w?: number;
    h?: number;
    /** Ratio */
    ar?: string;
    fit?: "crop";
  };
}

export const Image = (props: Props) => {
  const {
    className = "",
    src,
    alt,
    style = {},
    loadedAnyway = false,
    dataCy = "",
    size = {},
  } = props;
  const [imageLoaded, setImageLoaded] = useState(false);

  const finalLoaded = useMemo(
    () => loadedAnyway || imageLoaded,
    [loadedAnyway, imageLoaded]
  );

  useEffect(() => {
    if (!src) return;

    setImageLoaded(true);
  }, [src]);

  if (!src) return null;

  if (src?.includes("imgix.net")) {
    return (
      <Imgix
        imgixParams={{
          q: 70,
          ...(size || {}),
        }}
        width={size?.w}
        height={size?.h}
        src={src}
        className={classNames(
          className,
          finalLoaded && src ? "opacity-100" : "loader bg-background-secondary"
        )}
        htmlAttributes={{
          alt,
          "data-cy": dataCy,
          onLoad: () => setImageLoaded(true),
        }}
        sizes="70vw"
        attributeConfig={{
          src: "data-src",
          sizes: "data-sizes",
        }}
      />
    );
  }

  return (
    <img
      src={src}
      alt={alt}
      onLoad={() => setImageLoaded(true)}
      className={`${
        finalLoaded && src ? "opacity-100" : "loader bg-background-secondary"
      } ${className}`}
      style={{ transition: "opacity .2s ease-out", ...style }}
      data-cy={dataCy}
    />
  );
};
