import { useWindowSize } from "@kanpla/system";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal, { ModalProps } from "../../modal/Modal";
import { BottomDrawer, BottomDrawerProps } from "./";

type PassingProps = BottomDrawerProps & ModalProps;

interface Props extends PassingProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  drawerProps?: Partial<BottomDrawerProps>;
  modalProps?: Partial<ModalProps>;
}

export const DrawerOrModal = (props: Props) => {
  const { children, drawerProps = {}, modalProps = {}, open, setOpen } = props;

  const { width: screenWidth } = useWindowSize();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(open);
  }, [open]);

  const delayClose = () => {
    setMounted(false);
    setTimeout(() => {
      setOpen(false);
    }, 300);
  };

  // Rendering optimisations
  if (!open) return null;

  return screenWidth < 768 ? (
    <BottomDrawer
      {...props}
      {...drawerProps}
      open={mounted}
      setOpen={delayClose}
    >
      {children}
    </BottomDrawer>
  ) : (
    <Modal
      zIndex={props.zIndex || 60}
      {...props}
      {...modalProps}
      open={mounted}
      setOpen={delayClose}
    >
      {children}
    </Modal>
  );
};
